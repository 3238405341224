import React from "react";
import "../styles/layout.scss";

const IndexPage = () => {
  return (
    <h2 className="index-page">
      <a
        href="https://pioneerworks.org/residency/"
        target="_blank"
      >
        Pioneer Works
      </a>
    </h2>
  )

};

export default IndexPage;
